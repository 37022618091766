import moment from 'moment-timezone';
import { getBackendTokens } from './backendTokenHelper';
import { makeGetRequest, makePostRequest, makePutRequest } from './apiHelper';

export const getSubscriptionDetails = async apiLinks => {
  const backendTokens = await getBackendTokens(apiLinks);
  const headers = {
    Authorization: `Bearer ${backendTokens.access_token}`
  };

  const subscriptionsResponse = await makeGetRequest(apiLinks.subscriptions.href, headers);
  return subscriptionsResponse._embedded.subscription;
};

export const initiateSubscription = async apiLinks => {
  const backendTokens = await getBackendTokens(apiLinks);
  const headers = {
    Authorization: `Bearer ${backendTokens.access_token}`
  };

  return makePostRequest(`${apiLinks.subscriptions.href}?paymentProviderKey=stripe`, headers);
};

export const confirmSubscription = async (apiLinks, confirmLink, subscriptionPurchase) => {
  const backendTokens = await getBackendTokens(apiLinks);
  const headers = {
    Authorization: `Bearer ${backendTokens.access_token}`,
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify({
    _embedded: {
      subscriptionPurchase
    }
  })

  return makePutRequest(confirmLink, headers, body);
};

export const createStripeSubscription = async (apiLinks, confirmationPayload) => {
  const response = await initiateSubscription(apiLinks);
  const paymentProvider = response._embedded.subscriptionPurchase.paymentProvider;
  const subscriptionPurchase = {
    paymentProvider,
    confirmationPayload
  };

  return confirmSubscription(apiLinks, response._links.confirm.href, subscriptionPurchase);
};

export const analyzeSubscription = (subscription, timeZoneId) => {
  const now = moment().tz(timeZoneId);
  const expiresAt = moment(subscription.expiresAt).tz(timeZoneId);
  const isExpired = now.isSameOrAfter(expiresAt);
  const daysLeft = Math.ceil(expiresAt.diff(now, 'days', true));
  const { phase, cancelAfterExpiry, monthlyPrice } = subscription;
  const isTrial = phase === 'trial';
  const isFree = monthlyPrice === 0;
  return {
    isTrial,
    isFree,
    isExpired,
    cancelAfterExpiry,
    daysLeft,
    expiresAt
  };
}
