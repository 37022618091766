export default {
  myaider_app_text_common_backToAppText: 'Take me back to the app',
  myaider_app_text_landingPage_mainHeading: 'Account Management',
  myaider_app_text_landingPage_accountDetails_mainTitle: 'Account Details',
  myaider_app_text_landingPage_accountDetails_userTitle: 'User',
  myaider_app_text_landingPage_accountDetails_businessTitle: 'Business',
  myaider_app_text_landingPage_subscription_description_trial: 'free Aider trial',
  myaider_app_text_landingPage_subscription_description_subscription: 'Aider subscription',
  myaider_app_text_landingPage_subscription_statusMessage_expired: 'Your {subscriptionDescription} has expired.',
  myaider_app_text_landingPage_subscription_statusMessage_willExpire: 'Your {subscriptionDescription} is about to expire in {daysLeft} days.',
  myaider_app_text_landingPage_subscription_statusMessage_currentFree: 'You have subscribed to Aider for free.',
  myaider_app_text_landingPage_subscription_statusMessage_currentPaid: 'You have subscribed to Aider at {currency} {monthlyPrice} per month. Your next billing date is on {nextBillingDate}.',
  myaider_app_text_landingPage_subscription_subscribeCTA_buttonLabel: 'Subscribe now',
  myaider_app_text_landingPage_subscription_subscribeCTA_description: 'to continue having all benefits from Aider.',
  myaider_app_text_landingPage_subscription_featureList_mainTitle: 'See features',
  myaider_app_text_paymentResult_success_mainHeading: 'Congratulations!',
  myaider_app_text_paymentResult_success_content: 'We have successfully processed your payment. Your Aider subscription starts today!',
  myaider_app_text_paymentResult_failed_mainHeading: 'Oops...',
  myaider_app_text_paymentResult_failed_content: 'There was an issue processing your payment. Please try again later.',
  myaider_app_text_paymentScreen_mainHeading: 'Subscription',
  myaider_app_text_paymentScreen_accountDetailsLabel: 'Your details',
  myaider_app_text_paymentScreen_priceLabel: 'Pricing information',
  myaider_app_text_paymentScreen_creditCardLabel: 'Credit card details',
  myaider_app_text_paymentScreen_payNowButtonText: 'Subscribe',
  myaider_app_text_paymentScreen_payNowButtonProcessingText: 'Processing...',
  myaider_app_text_paymentScreen_terms: 'Payment terms: There is a monthly fee for the use of Aider. The fee will be charged to your credit card immediately and then every month thereafter. It automatically renews unless unsubscribed at least 24 hours before current period ends. There is no long term commitment and no cancellation fees.'
}
