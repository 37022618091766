import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import AppContext from '../../appContext/appContext';
import PageLayout from '../common/pageLayout/pageLayout';
import { renderTemplate } from '../../lib/stringUtils';
import './paymentResult.css';

class PaymentResult extends React.Component {
  render() {
    const backToAppLink = renderTemplate(process.env.REACT_APP_BACK_TO_APP_LINK, {
      subscriptionUpdated: this.context.subscriptionUpdated
    });

    return (
      <PageLayout className="payment-result" backLink="/">
        {
          this.props.success
            ? <section>
                <h1 className="title">
                  {this.context.remoteConfig.getValue('paymentResult.success.mainHeading')}
                </h1>
                <p className="centered bold">
                  {this.context.remoteConfig.getValue('paymentResult.success.content')}
                </p>
              </section>
            : <section>
                <h1 className="title">
                  {this.context.remoteConfig.getValue('paymentResult.failed.mainHeading')}
                </h1>
                <p className="centered">
                  {this.context.remoteConfig.getValue('paymentResult.failed.content')}
                </p>
              </section>
        }
        <div className="button-box">
          <Button href={backToAppLink} variant="outline-light">
            {this.context.remoteConfig.getValue('common.backToAppText')}
          </Button>
        </div>
      </PageLayout>
    );
  }
}

PaymentResult.contextType = AppContext;
PaymentResult.propTypes = {
  success: PropTypes.bool.isRequired
};

export default PaymentResult;
