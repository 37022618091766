import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

class CollapsibleItem extends React.Component {
  render() {
    return (
      <Card>
        <Accordion.Toggle
          className={`collapsible-toggle ${this.props.eventKey} ${this.props.className}`}
          as={Card.Header}
          eventKey={this.props.eventKey}
          onClick={this.props.onClick}
        >
          <span className="title">{this.props.title}</span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.eventKey}>
          <Card.Body>{this.props.children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

CollapsibleItem.propTypes = {
  className: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CollapsibleItem;
