import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './components/landingPage/landingPage';
import PaymentScreen from './components/paymentScreen/paymentScreen';
import PaymentResult from './components/paymentResult/paymentResult';
import loadAppContext from './appContext/loadAppContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css'

class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/payment">
            <PaymentScreen />
          </Route>
          <Route exact path="/payment-success">
            <PaymentResult success={true} />
          </Route>
          <Route exact path="/payment-error">
            <PaymentResult success={false} />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default loadAppContext(App);
