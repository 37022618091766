import React from 'react';
import Button from 'react-bootstrap/Button';
import AppContext from '../../appContext/appContext';
import PageLayout from '../common/pageLayout/pageLayout';
import { renderTemplate } from '../../lib/stringUtils';
import './errorPage.css';

class ErrorPage extends React.Component {
  render() {
    const backToAppLink = renderTemplate(process.env.REACT_APP_BACK_TO_APP_LINK, {
      subscriptionUpdated: this.context.subscriptionUpdated
    });

    return (
      <PageLayout className="error-page" theme="blue">
        <div>
          <h1 className="title">Oops...</h1>
          <section className="message">
            You're not allowed to view this page or your session may have expired.
            Click the button below to go back to the Aider app.
          </section>
        </div>
        <div className="button-box">
          <Button href={backToAppLink} variant="outline-light">
            Take me back to the app
          </Button>
        </div>
      </PageLayout>
    );
  }
}

ErrorPage.contextType = AppContext;

export default ErrorPage;
