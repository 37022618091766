import React from 'react';
import AppContext from '../../appContext/appContext';
import PageLayout from '../common/pageLayout/pageLayout';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import CollapsibleItem from './collapsibleItem';
import SubscriptionStatus from './subscriptionStatus';
import { renderTemplate } from '../../lib/stringUtils';
import './landingPage.css';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggleArrows = this.toggleArrows.bind(this);
    this.state = {
      user: null,
      business: null
    };
  }

  componentDidMount() {
    document.querySelector('body').classList.add('blue-bg');
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('blue-bg');
  }

  toggleArrows(event) {
    if (event.target.classList.contains('collapsed')) {
      event.target.classList.remove('collapsed');
    } else {
      event.target.classList.add('collapsed');
    }
  }

  render() {
    const backToAppLink = renderTemplate(process.env.REACT_APP_BACK_TO_APP_LINK, {
      subscriptionUpdated: this.context.subscriptionUpdated
    });

    return (
      <PageLayout className="landing-page">
        <h1 className="heading centered">{this.context.remoteConfig.getValue('landingPage.mainHeading')}</h1>
        <Accordion defaultActiveKey="account-details">
          <CollapsibleItem
            eventKey="account-details"
            title={this.context.remoteConfig.getValue('landingPage.accountDetails.mainTitle')}
            onClick={this.toggleArrows}
          >
            <span className="bold">{this.context.remoteConfig.getValue('landingPage.accountDetails.userTitle')}</span><br/>
            <span>{this.context.user.name}</span><br/>
            <span>{this.context.user.email}</span><br/>
            {this.context.user.phoneNumber && <span>{this.context.user.phoneNumber}</span>}<br/>
            <br/>
            <span className="bold">{this.context.remoteConfig.getValue('landingPage.accountDetails.businessTitle')}</span><br/>
            <span>{this.context.business.name}</span><br/>
            <span>{this.context.business.area} {this.context.business.countryCode}</span>
          </CollapsibleItem>
        </Accordion>

        <SubscriptionStatus
          trialDescription={this.props.trialDescription}
        />

        <section className="take-me-back">
          <Button variant="link" href={backToAppLink}>
            {this.context.remoteConfig.getValue('common.backToAppText')}
          </Button>
        </section>
      </PageLayout>
    );
  }
}

LandingPage.contextType = AppContext;

export default LandingPage;
