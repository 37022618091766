import qs from 'qs';
import { makePostRequest } from './apiHelper';

const updateTokensExpiry = tokens => {
  tokens.expiresAt = Math.floor(Date.now() / 1000) + tokens.expires_in;
  return tokens;
};

const getIdTokenFromQueryString = () => {
  if (window.location.search) {
    const query = qs.parse(window.location.search.substring(1));
    if (query && query.idToken) {
      return query.idToken;
    }
  }

  return null;
}

export const initializeBackendTokens = async apiLinks => {
  // Check if there are already backend tokens existing  
  window.backendTokens = await getBackendTokens(apiLinks);
  if (window.backendTokens) {
    return window.backendTokens;
  }

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY)}`
  };

  const idToken = getIdTokenFromQueryString();
  if (!idToken) {
    return null;
  }

  const body = {
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    subject_token: idToken,
    subject_token_type: 'urn:ietf:params:oauth:token-type:id_token'
  };

  window.backendTokens = await makePostRequest(apiLinks.tokens.href, headers, qs.stringify(body)).then(updateTokensExpiry);

  return window.backendTokens;
};

export const getBackendTokens = async apiLinks => {
  if (!window.backendTokens) {
    return null;
  }

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY)}`
  };

  const body = {
    grant_type: 'refresh_token',
    refresh_token: window.backendTokens.refresh_token
  };

  const now = Math.floor(Date.now() / 1000);
  if ((now + process.env.BACKEND_TOKENS_EXPIRATION_OFFSET_SECONDS) >= window.backendTokens.expiresAt) {
    const refreshedTokens = await makePostRequest(apiLinks.tokens.href, headers, qs.stringify(body)).then(updateTokensExpiry);
    window.backendTokens = refreshedTokens;
  }

  return window.backendTokens;
};

export const clearTokens = () => {
  window.backendTokens = null;
};
