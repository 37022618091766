import jwt from 'jsonwebtoken';

export const getUserDataFromIdToken = backendTokens => {
  // Decode idToken without validating to get claims
  const decoded = jwt.decode(backendTokens.id_token);
  if (decoded && decoded.claims) {
    const user = {
      name: decoded.claims.displayName,
      email: decoded.claims.email,
      photoUrl: decoded.claims.photoUrl,
      phoneNumber: decoded.claims.phoneNumber
    };

    let business = null;
    if (decoded.claims.businesses && decoded.claims.businesses.length) {
      business = {
        name: decoded.claims.businesses[0].name,
        area: decoded.claims.businesses[0].area,
        countryCode: decoded.claims.businesses[0].countryCode,
        website: decoded.claims.businesses[0].website,
        timeZoneId: decoded.claims.businesses[0].timeZoneId
      };
    }

    return { user, business };
  }

  return {
    user: null,
    businesses: null
  }
}
