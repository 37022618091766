import React from 'react';
import Loader from '../components/common/loader/loader';
import ErrorPage from '../components/errorPage/errorPage';
import AppContext from './appContext';
import RemoteConfigWrapper from '../lib/remoteConfig/remoteConfigWrapper';
import { makeGetRequest } from '../lib/apiHelper';
import { initializeBackendTokens } from '../lib/backendTokenHelper';
import { getUserDataFromIdToken } from '../lib/userDataHelper';
import { getSubscriptionDetails } from '../lib/subscriptionHelper';

const status = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed'
};

const loadAppContext = WrappedComponent => {
  class ComponentWithContext extends React.Component {
    constructor (props) {
      super(props);
      this.state = {
        status: status.LOADING
      };

      this.refreshSubscription = this.refreshSubscription.bind(this);
    }

    async componentDidMount() {
      // Get API links
      const rootResponse = await makeGetRequest(process.env.REACT_APP_ROOT_ENDPOINT);
      const apiLinks = rootResponse._links;

      // Get config
      const configResponse = await makeGetRequest(apiLinks.config.href);
      const config = configResponse._embedded.configuration;

      // Load remote config
      const remoteConfig = new RemoteConfigWrapper();
      await remoteConfig.initialize();

      try {
        // Get backend tokens
        const backendTokens = await initializeBackendTokens(apiLinks);

        // Extract user and business data from token claims
        const { user, business } = getUserDataFromIdToken(backendTokens);

        const subscription = await getSubscriptionDetails(apiLinks);

        // Update the state
        this.setState({
          apiLinks,
          config,
          remoteConfig,
          backendTokens,
          user,
          business,
          subscription,
          status: status.LOADED,
          subscriptionUpdated: false,
          refreshSubscription: this.refreshSubscription
        });
      } catch (error) {
        this.setState({
          apiLinks,
          config,
          remoteConfig,
          subscriptionUpdated: false,
          status: status.FAILED
        });
      }
    }

    async refreshSubscription() {
      const subscription = await getSubscriptionDetails(this.state.apiLinks);
      return new Promise(resolve => {
        this.setState({ subscription, subscriptionUpdated: true }, resolve());
      });
    }

    render() {
      if (this.state.status === status.LOADING) {
        return <Loader isLoading={true} />
      }

      if (this.state.status === status.FAILED) {
        return (
          <AppContext.Provider value={this.state}>
            <ErrorPage />
          </AppContext.Provider>
        );
      }

      return (
        <AppContext.Provider value={this.state}>
          <WrappedComponent apiLinks={this.state.apiLinks} config={this.state.config} {...this.props} />
        </AppContext.Provider>
      );
    }
  }

  return ComponentWithContext;
};

export default loadAppContext;
