import React from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import AppContext from '../../appContext/appContext';
import PageLayout from '../common/pageLayout/pageLayout';
import CreditCardForm from './creditCardForm';
import { analyzeSubscription } from '../../lib/subscriptionHelper';
import './paymentScreen.css';

class PaymentScreen extends React.Component {
  constructor(props) {
    super(props);

    this.onSuccessfulPayment = this.onSuccessfulPayment.bind(this);
    this.onFailedPayment = this.onFailedPayment.bind(this);
    this.redirectIfAlreadySubscribed = this.redirectIfAlreadySubscribed.bind(this);
  }

  componentDidMount() {
    this.redirectIfAlreadySubscribed();
  }

  componentDidUpdate() {
    this.redirectIfAlreadySubscribed();
  }

  onSuccessfulPayment() {
    this.props.history.push(`/payment-success${this.props.location.search}`);
  }

  onFailedPayment() {
    this.props.history.push(`/payment-failed${this.props.location.search}`);
  }

  redirectIfAlreadySubscribed() {
    const analyzedSubscription = analyzeSubscription(this.context.subscription, this.context.business.timeZoneId);
    if (!analyzedSubscription.isExpired && !analyzedSubscription.isTrial && !analyzedSubscription.cancelAfterExpiry) {
      // If the user is already subscribed redirect back to landing page
      this.props.history.push(`/${this.props.location.search}`);
    }
  }

  render() {
    return (
      <StripeProvider apiKey={this.context.config.osp.stripe.apiKey}>
        <PageLayout className="payment-screen" backLink="/">
          <h1 className="heading">{this.context.remoteConfig.getValue('paymentScreen.mainHeading')}</h1>

          <p className="sub-section">
            <span className="bold">{this.context.remoteConfig.getValue('paymentScreen.accountDetailsLabel')}</span><br/>
            <span>{this.context.user.name}</span><br/>
            <span>{this.context.business.name}</span>
          </p>

          <p className="sub-section">
            <span className="bold">{this.context.remoteConfig.getValue('paymentScreen.priceLabel')}</span><br/>
            <span>{this.context.subscription.currency} {this.context.subscription.monthlyPrice} per month</span>
          </p>

          <Elements>
            <CreditCardForm
              onSuccessfulPayment={this.onSuccessfulPayment}
              onFailedPayment={this.onFailedPayment}
             />
          </Elements>

          <section className="terms-box">
            {
              this.context.remoteConfig.getValue('paymentScreen.terms')}
              &nbsp;
              <a href="https://www.aider.ai/terms" rel="noopener noreferrer" target="_blank">Terms</a> and
              &nbsp;
              <a href="https://www.aider.ai/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.
          </section>
        </PageLayout>
      </StripeProvider>
    );
  }
}

PaymentScreen.contextType = AppContext;

PaymentScreen.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(PaymentScreen);
