import React from 'react';

const AppContext = React.createContext({
  apiLinks: null,
  config: null,
  user: null,
  business: null,
  subscription: null,
  refreshSubscription: () => {}
});

export default AppContext;
