import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import AppContext from '../../appContext/appContext';
import CollapsibleItem from './collapsibleItem';
import { analyzeSubscription } from '../../lib/subscriptionHelper';
import { renderTemplate } from '../../lib/stringUtils';

class SubscriptionStatus extends React.Component {
  constructor(props) {
    super(props);

    this.toggleArrows = this.toggleArrows.bind(this);
    this.renderFeatures = this.renderFeatures.bind(this);
    this.updateSticky = this.updateSticky.bind(this);
    this.renderTemplate = this.renderTemplate.bind(this);
    this.stickyMarkerRef = React.createRef();
    this.stickyRef = React.createRef();
    this.featureRef = React.createRef();
  }

  componentDidMount() {
    document.body.onscroll = this.updateSticky;
  }

  toggleArrows(event) {
    if (event.target.classList.contains('collapsed')) {
      event.target.classList.remove('collapsed');
    } else {
      event.target.classList.add('collapsed');
    }
  }

  updateSticky() {
    if (!this.stickyMarkerRef || !this.stickyMarkerRef.current) {
      return;
    }

    const top = this.stickyMarkerRef.current.getBoundingClientRect().top;
    if (top <= 0) {
      this.stickyRef.current.classList.add('sticky');
      this.featureRef.current.classList.add('adjusted');
    } else {
      this.stickyRef.current.classList.remove('sticky');
      this.featureRef.current.classList.add('adjusted');
    }
  }

  renderFeatures() {
    return this.context.subscription.product.features.map((feature, index) => (
      <ListGroup.Item key={index}>
        <i className="material-icons icon">check_circle_outline</i>
        <span className="text">{feature}</span>
      </ListGroup.Item>
    ));
  }

  renderTemplate(template, view) {
    return renderTemplate(template, view);
  }

  render() {
    const analyzedSubscription = analyzeSubscription(this.context.subscription, this.context.business.timeZoneId);
    const subscriptionDescription = analyzedSubscription.isTrial
      ? this.context.remoteConfig.getValue('landingPage.subscription.description.trial')
      : this.context.remoteConfig.getValue('landingPage.subscription.description.subscription');
    const { currency, monthlyPrice } = this.context.subscription;

    let template;
    let subscribeNow = false;
    const templateView = {
      subscriptionDescription,
      daysLeft: analyzedSubscription.daysLeft,
      currency,
      monthlyPrice,
      nextBillingDate: analyzedSubscription.expiresAt.format('D MMMM YYYY')
    };
    if (analyzedSubscription.isExpired) {
      subscribeNow = true;
      template = this.context.remoteConfig.getValue('landingPage.subscription.statusMessage.expired');
    } else {
      if (analyzedSubscription.isTrial || analyzedSubscription.cancelAfterExpiry) {
        subscribeNow = true
        template = this.context.remoteConfig.getValue('landingPage.subscription.statusMessage.willExpire');
      } else if (analyzedSubscription.isFree) {
        template = this.context.remoteConfig.getValue('landingPage.subscription.statusMessage.currentFree');
      } else {
        template = this.context.remoteConfig.getValue('landingPage.subscription.statusMessage.currentPaid');
      }
    }

    const message = this.renderTemplate(template, templateView);
    const paymentLink = {
      pathname: '/payment',
      search: this.props.location.search
    };

    return (
      <section className="subscription-status">
        <section ref={this.stickyMarkerRef}></section>
        <section ref={this.stickyRef} className="subscribe-now">
          <p className="centered bold">{message}</p>
          {subscribeNow && <>
            
              <p className="centered">
                <Link className="btn btn-light" to={paymentLink}>
                  {this.context.remoteConfig.getValue('landingPage.subscription.subscribeCTA.buttonLabel')}
                </Link>
              </p>
              <p className="centered">{this.context.remoteConfig.getValue('landingPage.subscription.subscribeCTA.description')}</p>
          </>}
        </section>
        <Accordion className="feature-list">
          <CollapsibleItem
            eventKey="features"
            title={this.context.remoteConfig.getValue('landingPage.subscription.featureList.mainTitle')}
            className="collapsed"
            onClick={this.toggleArrows}
          >
            <ListGroup ref={this.featureRef} variant="flush">
              {this.renderFeatures()}
            </ListGroup>
          </CollapsibleItem>
        </Accordion>
        
      </section>
    );
  }
}

SubscriptionStatus.contextType = AppContext;
SubscriptionStatus.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(SubscriptionStatus);
