import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../logo.svg';
import './header.css';

const Header = props => {
  const linkTo = {
    pathname: props.backLink,
    search: window.location.search
  };

  return (
    <header className="header">
      <div className="inner-header">
        {props.backLink && <Link className="back-link" to={linkTo}>Back</Link>}
        <section className="logo-box">
          <img src={logo} alt="Aider" className="logo" />
        </section>
      </div>
    </header>
  );
};

export default Header;
