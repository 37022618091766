import defaultValues from './defaultValues';

const MIN_FETCH_INTERVAL_MILLIS = 1200000; // 20 minutes
const KEY_PREFIX = 'myaider_app_text';

class RemoteConfigWrapper {
  loadScript() {
    const initScript = document.createElement('script');
    initScript.src = process.env.REACT_APP_FIREBASE_AUTO_INIT_SCRIPT;
    document.body.appendChild(initScript);

    return new Promise(resolve => {
      initScript.addEventListener('load', () => resolve());
    });
  }

  async initialize() {
    await this.loadScript();

    const app = window.firebase.apps[0];
    this.remoteConfig = app.remoteConfig();
    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: MIN_FETCH_INTERVAL_MILLIS
    };

    this.remoteConfig.defaultConfig = defaultValues;
    return this.remoteConfig.fetchAndActivate();
  }

  getValue(key) {
    const underscoreKey = key.replace(/\./g, '_');
    return this.remoteConfig.getValue(`${KEY_PREFIX}_${underscoreKey}`).asString();
  }
}

export default RemoteConfigWrapper;
