import React from 'react';
import PropTypes from 'prop-types';
import './loader.css';

const Loader = props => (
  <>
    {
      props.isLoading
        ? <div className="loader"></div>
        : <>{props.children}</>
    }
  </>
);

Loader.propTypes = {
  isLoading: PropTypes.bool
};

Loader.defaultProps = {
  isLoading: false
};

export default Loader;
