import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header/header';
import './pageLayout.css';

const PageLayout = props => {
  return (
    <div className={`page-layout ${props.className}`}>
      <Header backLink={props.backLink}/>
      <div className="inner-section">
        <section className="page-section">
          {props.children}
        </section>
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  className: PropTypes.string.isRequired,
  displayHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  backLink: PropTypes.string,
};

PageLayout.defaultProps = {
  displayHeader: false,
  backLink: null
};

export default PageLayout;
