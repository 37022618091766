import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { injectStripe, CardElement } from 'react-stripe-elements';
import AppContext from '../../appContext/appContext';
import { createStripeSubscription } from '../../lib/subscriptionHelper';
import stripeLogo from './stripeLogo.svg';

class CreditCardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      messageType: null,
      submitting: false,
      showForm: true
    };

    this.doSubmit = this.doSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  displayMessage(message, type) {
    if (this.state.message !== message) {
      this.setState({ message, messageType: type });
    }
  }

  processSubscription(cardToken) {
    return createStripeSubscription(this.context.apiLinks, cardToken);
  }

  async doSubmit() {
    this.displayMessage(null, null);
    const result = await this.props.stripe.createToken();
    if (result.error) {
      this.displayMessage(result.error.message, 'error');
      this.setState({ submitting: false });
    } else {
      try {
        await this.processSubscription(result.token.id);
        await this.context.refreshSubscription();
        this.props.onSuccessfulPayment();
      } catch(error) {
        this.props.onFailedPayment();
      }
    }
  }

  async onSubmit(event) {
    event.preventDefault();
    if (this.state.submitting === false) {
      this.setState({ submitting: true }, this.doSubmit);
    }
  }

  render() {
    // Workaround for iOS Safari zooming in on focus
    const cardElementStyle = {
      base: {
        fontSize: '16px'
      }
    };

    return (
      <Form onSubmit={this.onSubmit} className="credit-card-form sub-section">
        <Form.Group>
          <Form.Label className="bold">{this.context.remoteConfig.getValue('paymentScreen.creditCardLabel')}</Form.Label>
          <CardElement className="credit-card" hidePostalCode={true} style={cardElementStyle} />
          {this.state.message && <Form.Text className={this.state.messageType}>{this.state.message}</Form.Text>}
          <img className="stripe-logo" src={stripeLogo} alt="Powered by Stripe" />
        </Form.Group>
        {
          this.state.submitting
            ? <Button disabled={true}>
                <Spinner as="span" animation="border" size="sm" role="status" />
                <span>{this.context.remoteConfig.getValue('paymentScreen.payNowButtonProcessingText')}</span>
              </Button>
            : <Button type="submit">{this.context.remoteConfig.getValue('paymentScreen.payNowButtonText')}</Button>
        }
      </Form>
    );
  }
}

CreditCardForm.contextType = AppContext;
CreditCardForm.propTypes = {
  onSuccessfulPayment: PropTypes.func.isRequired,
  onFailedPayment: PropTypes.func.isRequired
};

export default injectStripe(CreditCardForm);
